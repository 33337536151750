import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", {
    attrs: {
      "default-query-strings": _vm.defaultValueQuery,
      "default-filters": _vm.defaultValueFilter,
      "has-filter-inactive": false
    }
  }, [_c("select-filter-v-2", {
    key: "Whs",
    attrs: {
      "label": "Whs",
      "source": "code",
      "placeholder": "Choose Whs",
      "mode": "multiple",
      "source-label": "name",
      "has-option-all": true,
      "reference": "export-order.common.whs",
      "delete-filter": "IsInactive",
      "advanced-filters": _vm.customFilter
    }
  }), _c("select-filter-v-2", {
    key: "Dvsn",
    attrs: {
      "mode": "multiple",
      "label": "Dvsn",
      "source": "code",
      "placeholder": "Choose Dvsn",
      "source-label": "name",
      "has-option-all": true,
      "reference": "export-order.common.dvsn",
      "delete-filter": "IsInactive",
      "advanced-filters": _vm.customFilter
    }
  }), _c("select-filter-v-2", {
    key: "StatDescr",
    attrs: {
      "mode": "multiple",
      "label": "Stat Descr",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Stat Descr",
      "has-option-all": true,
      "reference": "export-order.common.stat-descr",
      "delete-filter": "IsInactive"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterAdvanced",
  inject: ["crud"],
  data() {
    const selectedCountry = this.crud.getFilter("CountryCode");
    const customFilter = [
      [
        "CountryCode",
        {
          operator: FILTERS_EQUAL,
          value: selectedCountry ? selectedCountry.value : null
        }
      ]
    ];
    return {
      customFilter,
      defaultValueQuery: [["isCurrentMonth", true]],
      defaultValueFilter: [
        ["CountryCode", { operator: FILTERS_EQUAL, value: "AU" }]
      ]
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterAdvanced = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "filter-bunker"
  }, [_c("resource", {
    attrs: {
      "name": "export-order.common.whs",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.dvsn",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.stat-descr",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-statuses",
      "api-url": _vm.apiUrl,
      "redirect-route": "/export-orders/export-orders-status"
    }
  }, [_c("filter-advanced")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    FilterAdvanced
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
